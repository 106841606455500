.new-claim-button {
  background-color: #82D8FF !important;
  border-color: #82D8FF !important;
  font-weight: bold !important;
}

.action-buttons {
  border: none !important;
  box-shadow: none !important;
}

.pagination-text-color {
  color: #626D8A;
  margin-top: 0.7rem;
}

.data-pill {
  border-radius: 50px !important;
  background-color: #DEE4EC !important;
}

/* .table-chip {
  border-radius: 50px !important;
  display: flex !important;
  justify-content: center !important;
 } */

.asc-dsc-button {
  border: none;
  background-color: transparent;
}