@import '../../assets/css/variables.scss';
.welcomeVh {
  min-height: 96vh;
}
.welcomeBg {
  background-image: url('../../assets/images/WelcomeBg.jpg');
  background-size: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.welcomeList {
  font-size: 18px;
  line-height: 24px;
  list-style-image: url('../../assets/images/tri-bullets.svg');

  li {
    padding-top: 25px;
  }
}
.color-white {
  color: #fff !important;
}
.headingFont {
  font-size: 45px;
}
.createAccountButton:disabled {
  opacity: 0.6;
}
.getInfoButton {
  background: $white-color;
  border: 1px solid #cacfdc;
  color: $primary-color !important;
  text-decoration: none !important;
  padding: 8px 16px;
  width: 150px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(17, 80, 244, 0.1),
    0px 2px 8px rgba(166, 175, 197, 0.3);
  border-radius: 12px;
}
.howItWorks {
  background: transparent;
  border: none;
  color: $white-color !important;
  text-decoration: none !important;
  padding: 8px 16px;
  width: 230px;
}
.signModal {
  padding: 1rem 34px !important;
}
.email-exist-modalButton-wrapper {
  display: flex !important;
  justify-content: space-between;
}
.createAccount-text {
  max-width: 250px;
  font-size: 20px;
  color: #0f0f59;
}
.Conatct_modal .active-content {
  // right: 17% !important;
  width: 660px !important;
}
.Conatct_modal .active-content > div:first-child {
  // right: 17% !important;
  padding: 12px 48px 5px !important;
}
.contatModalContainer {
  padding: 0px 48px !important;
}
.modal_CenterView .active-content {
  width: 480px !important;
  top: 34% !important;
}
.modal_CenterView .active-content > div:first-child {
  padding: 0px 0px 0px !important;
}
.modal_CenterView .active-content > div:nth-child(2) {
  padding: 0.2rem 34px 1rem !important;
}
//Media Queries
@media (max-width: 767px) {
  .welcomeList {
    font-size: 16px !important;
    width: 100% !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  .welcomeBg {
    padding-left: 30px !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}
