@import './assets/css/variables.scss';
@import './assets/css/Main.scss';

body {
  font-family: 'CoreSans45' !important;
  color: $secondary-content;
}
* {
  outline: none !important;
}

.lsp-1 {
  letter-spacing: 1px;
}

.lsp-2 {
  letter-spacing: 2px;
}

.primaryBg {
  background-color: $primary-color;
}
.whiteColor {
  color: $white-color !important;
}
.f-10 {
  font-size: 10px;
}
.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-18 {
  font-size: 18px;
}
.f-17 {
  font-size: 17px;
}
.f-20 {
  font-size: 20px;
}
.regularFont {
  font-family: 'CoreSans45' !important;
}

.main-wrapper {
  background: #e5e5e5;
  padding: 20px 30px;
}
.borderNone {
  border: none !important;
}

.f-09 {
  font-size: 9px;
}
.primaryBodyColor {
  color: $secondary-content !important;
}
.secondaryGreenColor {
  color: $secondary-green !important;
}
.secondaryBlueColor {
  color: $secondary-blue !important;
}
.primaryBlueColor {
  color: $primary-color !important;
}
.semiboldFont {
  font-family: 'CoreSans65';
}
.lh-30 {
  line-height: 30px;
}
.customInput {
  background: $white-color;
  border: 1px solid $input-border;
  box-shadow: inset 0px 2px 6px rgba(140, 148, 168, 0.16);
  border-radius: 6px;
  outline: none !important;
  height: 40px;
  font-size: 14px;
  padding: 8px;
}
.customInput:disabled {
  background: #c4c4c42e;
  border-color: #c4c4c42e;
}

.postion-relative {
  position: relative;
}
.selectInput input {
  background: $white-color;
  border: 1px solid $input-border;
  box-shadow: inset 0px 2px 6px rgba(140, 148, 168, 0.16);
  border-radius: 6px;
  outline: none !important;
  height: 40px;
  font-size: 14px;
  padding: 8px;
  width: 100%;
}
.customSelect {
  background: $white-color;
  border: 1px solid $input-border;
  box-shadow: inset 0px 2px 6px rgba(140, 148, 168, 0.16);
  border-radius: 6px;
  outline: none !important;
  height: 40px;
  font-size: 14px;
  padding: 8px;
  width: 100%;
}
.createAccountButton {
  background-color: $secondary-blue;
  color: $primary-color;
  padding: 8px;
  outline: none;
  box-shadow: 0px 1px 2px rgba(17, 80, 244, 0.1), 0px 2px 8px rgba(166, 175, 197, 0.3);
  border-radius: 12px;
  border: none;
  height: 44px;
}
.manageUsersButton {
  background-color: $secondary-blue;
  color: $primary-color;
  padding: 8px 16px;
  outline: none;
  box-shadow: 0px 1px 2px rgba(17, 80, 244, 0.1), 0px 2px 8px rgba(166, 175, 197, 0.3);
  border-radius: 5px;
  border: none;
  height: 36px;
  div {
    font-size: 14px;
  }
}

.startApplicationButton {
  background-color: $secondary-blue;
  color: $primary-color;
  padding: 8px 16px;
  outline: none;
  box-shadow: 0px 1px 2px rgba(17, 80, 244, 0.1), 0px 2px 8px rgba(166, 175, 197, 0.3);
  border-radius: 5px;
  border: none;
  height: 36px;
  div {
    font-size: 14px;
  }
}
.editContactButton {
  background-color: transparent;
  color: $primary-color !important;
  padding: 8px 16px;
  outline: none;
  border-radius: 5px;
  border: none;
  height: 36px;
  div {
    font-size: 14px;
  }
}
.errorMessage {
  color: red !important;
  font-size: 12px;
}
.btn:focus {
  box-shadow: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.cursor-pointer {
  cursor: pointer !important;
}
.text-right {
  text-align: right !important;
}
.modal-content {
  border: none !important;
  border-radius: 15px !important;
}
.loaderHeight {
  height: 50vh;
  width: 100%;
}
.react-datepicker {
  border: none !important;
  // -webkit-box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.75);
  //  -moz-box-shadow: -1px 1px 5px 9px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  top: 0;
  margin-top: 0px;
}
.react-datepicker__header {
  background-color: #fff !important;
  border: none !important;
  //  border:1px solid $secondary-content !important;
  //border:1px solid $secondary-content !important;
}

.react-datepicker__day-name {
  color: #e5e5e5 !important;
  font-weight: bold;
  margin: 2px !important;
}
.react-datepicker__day {
  border-bottom: none;
  margin: 5px;
}
.dateSelect {
  width: 100% !important;
  margin: 2px !important;
  height: 25px;
  padding: 4px;
  border: none !important;
  border-radius: 3px;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: $secondary-content !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: $secondary-content !important;
}

.css-1rhbuit-multiValue {
  padding-left: 20px;
  background-color: #007aff !important;
  position: relative;
  color: #fff !important;
  border-radius: 15px !important;
  .css-xb97g8 {
    border-radius: 15px;
    background-color: #fff;
    width: 15px;
    height: 15px;
    margin: 5px;
    color: #007aff;
    padding: 2px;
  }
  .css-xb97g8:hover {
    background-color: #fff !important;
    color: #007aff !important;
  }
}
.css-1rhbuit-multiValue:after {
  position: absolute;
  content: '';
  background-image: url('./assets/images/tick.svg');
  background-size: 12px;
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  top: 7px;
  left: 7px;
}
.css-12jo7m5 {
  color: #fff !important;
}
.inheritFont {
  font-family: inherit !important;
}

.inheritfont {
  font-family: inherit !important;
}
.heightButton {
  height: 44px !important;
}
.h1Font {
  font-size: 2.5rem !important;
}

.f-11 {
  font-size: 11px !important;
}

div[class^='ModalContent'] {
  div[class^='StyledComponents__Container'] {
    display: none;
  }
  div[class^='ModalBody'] {
    display: none;
  }
  div[class^='ModalFooter'] {
    display: none;
  }
}

div[class^='ModalContent'].active-content {
  div[class^='StyledComponents__Container'] {
    display: block !important;
  }
  div[class^='ModalBody'] {
    display: block !important;
  }
  div[class^='ModalFooter'] {
    display: block !important;
  }
}

.multiselect__menu-portal {
  left: initial !important;
}

.subHeader {
  background: $sidenav-bg;
  height: 54px;
  display: flex;
  align-items: center;
  div {
    font-size: 14px;
  }
}
// this will hide all dropdown-carets; may have to refactor in the future
.dropdown-caret {
  visibility: hidden;
  width: 0;
  height: 0;
}

div[id^=era-claim-payment-header], div[id^=era-claim-service-header]{
  font-weight: bold;
  padding-left: 10px;
}

.margin-top-bottom-10px{
  margin-top: 10px;
  margin-bottom: 10px;

  span{
    margin-bottom: 3px;
  }
}

.tab_container_content_class{
  min-height: 100vh;
  font-size: 14px;
  font-family: 'Courier New', Courier, monospace;
  line-height: 1.5;
  color: #000000;
}

.tab-content-spacing{
  white-space: pre-line;
}

.eligibility-inquiry-header{
  color: #323334!important;
}
@media print {
  @page {
    margin: 0;
  }

  
  .no-print {
    display: none !important;
  }

  .printable-section .printable-section * {
    visibility: visible;
  }

  .drawerContainer, .drawerContainer * {
    visibility: visible;
  }


  .printable-section {
    padding: 10px;
    left: 0;
    top: 10;
    width: 100%;
    height: 100% !important;
  }
}
