.mandatory:after {
  content: '*';
  color: red;
}

header[class^='MastHeadNav'] + div {
  margin-top: 50px;
}

header[class^='MastHeadNav'] + div.welcome-container {
  margin-top: 48px;
}
