// Colors

$primary-color: #0f0f59;
$white-color: #fff;
$black-color: #000;
$secondary-green: #46eaa7;
$secondary-blue: #4cc6ff;
$secondary-content: #626d8a;
$input-border: #dee4ec;
$grey-color: #e5e5e5;
$sidenav-bg: #f0f3f8;

// Fonts

@font-face {
  font-family: "CoreSans35";
  src: url("../fonts/coresansc35-webfont.ttf");
}
@font-face {
  font-family: "CoreSans45";
  src: url("../fonts/coresansc45-webfont.ttf");
}
@font-face {
  font-family: "CoreSans45it";
  src: url("../fonts/coresansc45it-webfont.ttf");
}
@font-face {
  font-family: "CoreSans65";
  src: url("../fonts/coresansc65-webfont.ttf");
}
@font-face {
  font-family: "CoreSans75";
  src: url("../fonts/coresansc75-webfont.ttf");
}
@font-face {
  font-family: "Core Sans C";
  src: url("../fonts/coresansc45-webfont.ttf");
}
